<template>
    <section>
        <div id="mobile_menu" class="mobileonly">
            <div id="top">
                <div id="logo">
                    <div id="logo_img" />
                    <div id="logo_text" />
                </div>
                <div id="button_close" @click="close_menu()" />
            </div>
            <div id="menu">
                <div class="item" @click="go_to_section('#section_home', true)">HOME</div>
                <div class="item" @click="go_to_section('#section_about', true)">ABOUT</div>
                <div class="item" @click="go_to_section('#section_vision', true)">VISION</div>
                <div class="item" @click="go_to_section('#section_roadmap', true)">ROADMAP</div>
                <div class="item" @click="go_to_section('#section_contact', true)">CONTACT</div>
            </div>
            <div id="bottom">© 2023 Security Token Offering. All rights reserved.</div>
        </div>
        <div id="sticky_menu" class="desktoponly">
            <div id="logo" />
            <div id="menu">
                <div class="item" @click="go_to_section('#section_home')">HOME</div>
                <div class="item" @click="go_to_section('#section_about')">ABOUT</div>
                <div class="item" @click="go_to_section('#section_vision')">VISION</div>
                <div class="item" @click="go_to_section('#section_roadmap')">ROADMAP</div>
                <div class="item" @click="go_to_section('#section_contact')">CONTACT</div>
            </div>
        </div>
        <div id="content_section">
            <div class="section" id="section_home">
                <div id="background1" />
                <div id="logoandmenu">
                    <div id="logo">
                        <div id="logo_img" />
                        <div id="logo_text" />
                    </div>

                    <div id="menu" class="desktoponly">
                        <div class="item" @click="go_to_section('#section_home')">HOME</div>
                        <div class="item" @click="go_to_section('#section_about')">ABOUT</div>
                        <div class="item" @click="go_to_section('#section_vision')">VISION</div>
                        <div class="item" @click="go_to_section('#section_roadmap')">ROADMAP</div>
                        <div class="item" @click="go_to_section('#section_contact')">CONTACT</div>
                    </div>
                    <a href="https://t.me/sto_official_chat"><div id="telegrame_button"></div></a>
                    <div id="button_menu" class="mobileonly" @click="open_menu()"></div>
                </div>
                <div id="content1">Security<br />Token Offering</div>
                <div id="content2" class="desktoponly">
                    NFTs become easy and secure gateways to events<br />
                    and experiences around the world
                </div>
                <div id="content2" class="mobileonly">
                    NFTs become easy and secure<br />
                    gateways to events<br />
                    and experiences around the world
                </div>
                <div id="content3">
                    <a href="./download/STO_whitepaper_v03_230303_113159.pdf" download><div>백서 다운로드</div></a>
                </div>
                <div id="content4" />
            </div>
            <div class="section" id="section_about">
                <div id="content1" class="title desktoponly">About</div>
                <div id="content2" class="desc desktoponly">
                    블록체인 산업은 지난 짧은 기간 동안 폭발적인 성장률을 보이며 우리의 삶에 크게 관여하고 있습니다.<br />
                    특히 코로나19로 인하여 우리 삶이 온라인으로 전환되는 속도는 더욱 빨라졌고 디지털에 대한 가치 인식도 커지고 있습니다.<br />
                    이러한 변화 속에서 모든 자산이 디지털 데이터화 되면서 NFT, STO 및 DeFi 등 다양한 형태의 블록체인 기반<br />
                    금융 서비스들 또한 우리 생활 깊숙이 자리잡을 것으로 예상되고 있습니다.
                </div>
                <div id="content2" class="desc mobileonly">
                    <div id="content1" class="title">About</div>
                    블록체인 산업은 지난 짧은 기간 동안 폭발적인 성장률을<br />
                    보이며 우리의 삶에 크게 관여하고 있습니다.<br />
                    특히 코로나19로 인하여 우리 삶이 온라인으로 전환되는 속도는<br />
                    더욱 빨라졌고 디지털에 대한 가치 인식도 커지고 있습니다.<br />
                    이러한 변화 속에서 모든 자산이 디지털 데이터화 되면서 NFT,<br />
                    STO 및 DeFi 등 다양한 형태의 블록체인 기반 금융 서비스들<br />
                    또한 우리 생활 깊숙이 자리잡을 것으로 예상되고 있습니다.
                </div>
                <div id="content3" class="desc desktoponly">
                    STO는 토큰화된 대체 투자를 위한 최초의 분산형 글로벌 시장이며,<br />
                    전 세계 사람들에게 디지털 자산에 대한 액세스를 제공하는 것이 목표입니다.
                </div>
                <div id="content3" class="desc mobileonly">
                    STO는 토큰화된 대체 투자를 위한 최초의 분산형<br />
                    글로벌 시장이며, 전 세계 사람들에게 디지털 자산에<br />
                    대한 액세스를 제공하는 것이 목표입니다.
                </div>
                <div id="image1" />
            </div>
            <div class="section" id="section_vision">
                <div id="content1" class="title desktoponly">Vision</div>
                <div id="content2" class="desc desktoponly">
                    ACP 플랫폼은 투자자와 자산 발행자라는 두 가지 주요 고객 그룹을 대상으로 합니다.<br />
                    개인 투자자를 위해 ACP 플랫폼은 디지털 자산에 대한 안전한 엑세스와<br />
                    최고의 토큰화된 투자를 제공할 수 있는 위치에 있습니다.
                </div>
                <div id="content3" class="contentbox desktoponly">
                    <div id="content_title">토큰화 기계</div>
                    <div id="content_text">
                        민간 기업, VC 회사 또는 펀드와 같은 자산 발행자는 STO 플랫폼을 사용하여<br />
                        쉽게 토큰을 발행하고 글로벌 투자자 커뮤니티에 배포할 수 있습니다.
                    </div>
                </div>
                <div id="content4" class="contentbox desktoponly">
                    <div id="content_title">토큰을 위한 2차 시장 생성</div>
                    <div id="content_text">
                        STO 플랫폼은 자산 기반 토큰 및 코인의 초기 상장 및 2차 거래를 위한 시장 역할을<br />
                        합니다. 블록체인 기반 실시간 실행 및 결제를 통해 자동화된 거래 및 더 높은 유동성<br />
                        이 가능합니다.
                    </div>
                </div>
                <div id="content5" class="contentbox desktoponly">
                    <div id="content_title">규정 준수 및 법률</div>
                    <div id="content_text">
                        ACP 플랫폼은 해당 규정에 따라 클라이언트 온보딩을 실행합니다.<br />
                        규정 준수 요구 사항은 지갑의 화이트 라벨링을 허용하는 스마트 계약에 포함됩니<br />
                        다. 회사의 파트너를 통해 보안 토큰을 발행하기 위한 간단하고 표준화된<br />
                        법인 및 구조 설정을 제공합니다.
                    </div>
                </div>
                <div id="content6" class="contentbox desktoponly">
                    <div id="content_title">암호화폐 보관 자산</div>
                    <div id="content_text">STO 플랫폼은 안전한 암호화 자산 보관 솔루션과 에스크로 서비스를 제공합니다.</div>
                </div>
                <div id="content7" class="contentbox desktoponly">
                    <div id="content_title">은행 서비스</div>
                    <div id="content_text">
                        라이선스 프로세스를 통과하면 STO 네트워크 내의 자산 발행자 및 파트너에게<br />
                        은행 계좌 및 결제와 같은 은행 서비스를 제공할 것입니다.
                    </div>
                </div>
                <div id="content2" class="desc mobileonly">
                    <div id="content1" class="title">Vision</div>
                    ACP 플랫폼은 투자자와 자산 발행자라는 두 가지<br />
                    주요 고객 그룹을 대상으로 합니다.<br />
                    개인 투자자를 위해 ACP 플랫폼은 디지털 자산에 대한<br />
                    안전한 엑세스와 최고의 토큰화된 투자를 제공할 수 있는<br />
                    위치에 있습니다.
                </div>
                <!-- <div id="progress_out" class="mobileonly">
                    <div id="progress_in"></div>
                </div>
                <div id="image_gear" class="mobileonly">
                </div> -->
                <div id="slide" class="mobileonly">
                    <swiper class="mySwiper" :slidesPerView="'auto'" :spaceBetween="10" :pagination="pagination" :modules="modules" :loop="true" :autoplay="{ delay: 3000 }">
                        <swiper-slide>
                            <div id="slide_item1" class="slide_item">
                                <div id="image" />
                                <div id="content_box">
                                    <div id="title">토큰화 기계</div>
                                    <div id="text">
                                        민간 기업, VC 회사 또는 펀드와 같은 자산 발행자는<br />
                                        STO 플랫폼을 사용하여 쉽게 토큰을 발행하고<br />
                                        글로벌 투자자 커뮤니티에 배포할 수 있습니다.
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div id="slide_item2" class="slide_item">
                                <div id="image" />
                                <div id="content_box">
                                    <div id="title">토큰을 위한 2차 시장 생성</div>
                                    <div id="text">
                                        STO 플랫폼은 자산 기반 토큰 및 코인의 초기 상장 및<br />
                                        2차 거래를 위한 시장 역할을 합니다.<br />
                                        블록체인 기반 실시간 실행 및 결제를 통해 자동화된<br />
                                        거래 및 더 높은 유동성이 가능합니다.<br />
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div id="slide_item3" class="slide_item">
                                <div id="image" />
                                <div id="content_box">
                                    <div id="title">규정 준수 및 법률</div>
                                    <div id="text">
                                        ACP 플랫폼은 해당 규정에 따라 클라이언트 온보딩을<br />
                                        실행합니다. 규정 준수 요구 사항은 지갑의 화이트 라벨링<br />
                                        을 허용하는 스마트 계약에 포함됩니다. <br />
                                        회사의 파트너를 통해 보안 토큰을 발행하기 위한<br />
                                        간단하고 표준화된 법인 및 구조 설정을 제공합니다.
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div id="slide_item4" class="slide_item">
                                <div id="image" />
                                <div id="content_box">
                                    <div id="title">암호화폐 보관 자산</div>
                                    <div id="text">
                                        STO 플랫폼은 안전한 암호화 자산 보관 솔루션과 <br />
                                        에스크로 서비스를 제공합니다.
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div id="slide_item5" class="slide_item">
                                <div id="image" />
                                <div id="content_box">
                                    <div id="title">은행 서비스</div>
                                    <div id="text">
                                        라이선스 프로세스를 통과하면 STO 네트워크 내의<br />
                                        자산 발행자 및 파트너에게 은행 계좌 및 결제와 같은<br />
                                        은행 서비스를 제공할 것입니다.
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <div id="image1" />
            </div>
            <div class="section" id="section_token_distribute">
                <div id="content1" class="title">Token Distribution</div>
                <div id="image1" />
                <div id="image2" />
            </div>
            <div class="section" id="section_roadmap">
                <div id="content1" class="title">ROADMAP</div>
                <div id="content2" class="title">2023</div>
                <div id="timeline" class="desktoponly" />
                <div id="slide" class="desktoponly">
                    <div id="item" v-for="(item, index) in roadmap_items" :key="index">
                        <div id="bullet" />
                        <div id="box">
                            <div id="title" v-html="item.title" />
                            <div id="text" v-html="item.text" />
                        </div>
                    </div>
                </div>
                <div id="slide" class="mobileonly">
                    <div id="timeline" />
                    <div id="item" v-for="(item, index) in roadmap_items" :key="index">
                        <div id="bullet" />
                        <div id="box">
                            <div id="title" v-html="item.title" />
                            <div id="text" v-html="item.text" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" id="section_contact">
                <div>
                    <div id="content1">Contact</div>
                    <div id="content2" class="desktoponly">비지니스 제안 및 문의는 STO 공식 텔레그램으로 연락해주십시오.</div>
                    <div id="content2" class="mobileonly">
                        비지니스 제안 및 문의는 STO 공식 텔레그램으로<br />
                        연락해주십시오.
                    </div>
                    <a href="https://t.me/sto_official_chat"><div id="button_telegram">텔레그램 입장하기</div></a>
                </div>
                <div id="bottom_logo">
                    <div id="logobox">
                        <div id="image" />
                        <div id="text" />
                    </div>
                    <div id="bottom_text">© 2023 Security Token Offering. All rights reserved.</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from "jquery";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper";
// import Panzoom from "@panzoom/panzoom";
// import "swiper/css";
import "swiper/css/pagination";
let b_show_sticky_menu = false;

export default {
    name: "App",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiper: null,
            // panzoom: null,
            about_items: [
                {
                    image: "icon_private_patient.svg",
                    text: "개인, 환자"
                },
                {
                    image: "icon_medical_provider.svg",
                    text: "의료 공급자"
                },
                {
                    image: "icon_civil_company.svg",
                    text: "민간기업"
                }
            ],
            roadmap_items: [
                { title: "Q1", text: "코인 런칭 발행<br/>거래소 상장" },
                { title: "Q2", text: "STO 결제 및 리워드 연동" },
                { title: "Q3", text: "STO 플랫폼 결제 및 리워드 연동<br/>가상자산 결제 서비스 오픈<br/>블록체인 월렛 서비스 추가" },
                { title: "Q4", text: "거래소 추가 상장<br/>NFT 추가 및 마켓 플레이스 확장<br/>간편결제 리워드 도입" }
            ],

            modules: [Autoplay, Pagination],
            pagination: {
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"></span>';
                }
            }
        };
    },

    mounted() {
        document.addEventListener("scroll", () => {
            var scroll = $(window).scrollTop();
            // console.log(scroll);
            if ($(window).width() < 700) return;
            if (scroll > 120 && !b_show_sticky_menu) this.show_sticky_menu();
            if (scroll < 120 && b_show_sticky_menu) this.hide_sticky_menu();
        });

        const resize_handler = () => {
            let windowsize = window.innerWidth;
            if (windowsize > 1920) windowsize = 1920;
            let scale_rate = windowsize / 1920;
            $("body").css("--scale-value", scale_rate);
            $("body").css("--translate-value", "0%");
            $("body").css("--section-height", 6558 * scale_rate + "px");
            if (windowsize < 700) $("#sticky_menu").css("display", "none");
        };
        $(window).on("resize", resize_handler);

        let jelement = $("#section_roadmap #slide.mobileonly");

        jelement.on("scroll", () => {
            if ($(window).width() > 700) return;
            let ele = document.querySelector("#section_roadmap #slide.mobileonly");
            let max_scroll = ele.scrollWidth - ele.clientWidth;
            let var_scroll = ele.scrollLeft;
            let per = (var_scroll * 100) / max_scroll + "%";
            $("#progress_in").css("width", per);
            // console.log(ele.scrollWidth - ele.clientWidth);
            // console.log(document.querySelector("#section_roadmap #slide.mobileonly").scrollWidth);
            // console.log(jelement.scrollLeft());
        });

        resize_handler();

        this.refresh_progress();
    },

    methods: {
        refresh_progress()
        {
            $("div#progress_in").css("width", "0%");
            $("div#progress_in").animate({
                "width" : "100%",
            }, 2000, "swing", ()=>{
                this.refresh_progress();
            }
            );

        },
        open_zoom() {
            $("div#zoom_image").fadeIn();
            // this.panzoom = Panzoom(document.querySelector("div#zoom_image_container"), { contain: "outside" });
        },
        close_zoom() {
            $("div#zoom_image").fadeOut();
            // this.panzoom.destroy();
        },
        download(link) {
            window.open(link);
        },
        openlink(link) {
            window.open(link);
        },
        open_menu() {
            $("div#mobile_menu").animate({
                left: 0
            });
        },
        close_menu() {
            $("div#mobile_menu").animate({
                left: "100%"
            });
        },

        on_swiper(s) {
            this.swiper = s;
        },

        go_to_section(section_id, close_menu = false) {
            if (close_menu) this.close_menu();
            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $(section_id).offset().top
                },
                500
            );
        },

        show_sticky_menu() {
            b_show_sticky_menu = true;
            $("#sticky_menu").fadeIn(200);
            // console.log("show");
        },

        hide_sticky_menu() {
            b_show_sticky_menu = false;
            $("#sticky_menu").fadeOut(200);
            // console.log("hide");
        },

        on_mouseover_portfolio(pid) {
            if ($(window).width() < 1000) return;
            var div_id = "div[data-id='" + pid + "']";
            $(div_id + " div#sub_div").css({
                visibility: "visible"
            });
            // console.log("on", pid);
        },

        on_mouseout_portfolio(pid) {
            if ($(window).width() < 1000) return;
            var div_id = "div[data-id='" + pid + "']";
            $(div_id + " div#sub_div").css({
                visibility: "hidden"
            });
            // console.log($(div_id + " div#sub_div"), "off", pid);
        },
        on_but_slide_next() {},
        on_but_slide_prev() {
            // this.swiper.slidePrev();
        }
    }
};
</script>
<style></style>

<style lang="scss" src="@/assets/css/common.scss"></style>
<style lang="scss" src="@/assets/css/desktop.scss"></style>
<style lang="scss" src="@/assets/css/mobile.scss"></style>
